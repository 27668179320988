<template>
    <div class="welcome">
        <img src="@/assets/wizard/trackinghint.png" srcset="@/assets/wizard/trackinghint@2x.png 2x" alt=""/>
        <h1>Trainiere mit echten Daten!</h1>
        <p>Möchtest du <span>GoogleFit</span> oder <span>Apple Health</span> mit SportlerPlus verknüpfen, um deine realen Fitnessdaten zu synchronisieren?</p>
        <v-btn class="button button--primary" @click="requestAccess">Zugriff erlauben</v-btn>
        <small><router-link to="/">Später vielleicht</router-link></small>
    </div>
</template>

<script>
export default {
    name: "TrackingHint",
    methods: {
        requestAccess() {
            if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.healthKitPermissionHandler) {
                window.webkit.messageHandlers.healthKitPermissionHandler.postMessage({"bogus": "value"});
            }
            if (window.AndroidJSInterface != undefined) {
                window.AndroidJSInterface.requestGoogleFitPermission();
            }
            this.$router.push("/");
        }
    }
}
</script>

<style lang="scss">
    .welcome {
        display: flex;
        flex-direction: column;
        text-align: center;
        min-height: calc(100% + 68px);
        margin-top: -68px;

        img {
            width: 100%;
            max-width: 292px;
            margin: auto auto 6px;
        }

        p {
            margin-top: 7px;
            font-weight: 500;
            margin-bottom: auto;
            opacity: 1;
            color: rgba($colorBlack, .64);

            span {
                font-weight: 600;
                color: $colorBlack;
            }
        }

        .button--secondary {
            font-size: 17px !important;
            padding: 20px 8px !important;
        }
    }
</style>